import logo from './assets/logo.svg'
import { create } from '@storybook/theming/create'

export default create({
  base: 'black',
  fontBase: '"Open Sans", sans-serif',
  fontCode: 'monospace',

  brandTitle: 'PROJECT2',
  brandUrl: '#',
  brandImage: logo,
  brandTarget: '_self',

  colorSecondary: '#FFB470',
  appBg: '#303A48',
  appContentBg: '#303A48',
  barBg: '#FFFFFF0F',
  inputBg: '#FFFFFF0A',
  inputBorder: '#FFFFFF1F',
})
